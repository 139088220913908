<template>
  <div class="view">
    <ProgressBar :step="step" :states="states" v-show="step != 2" />
    <div class="main">
      <div class="px-3">
        <div class="wrapper h-100">
          <div v-show="step == 0">
            <div class="mb-5">
              <h6 style="color: #ff0000">必填欄位*</h6>
              <h4>賠償者<span class="required">*</span></h4>
              <div class="row mb-3 p-1">
                <div
                  :id="'compensator-option-' + key"
                  class="card col-4"
                  :class="{ selected: compensator === option.value }"
                  @click="compensator = option.value"
                  v-for="(option, key) in $store.state.compensator_options"
                  :key="key"
                >
                  <div class="row align-items-center">
                    <div class="col">
                      <h6 class="text-center mb-0 mt-2">{{ option.text }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-5" v-show="compensator !== null">
              <div class="row">
                <div class="col">
                  <h4>賠償方式<span class="required">*</span></h4>
                  <div class="row mb-5 p-1">
                    <div
                      id="compensation-option-1"
                      class="col-4 card"
                      :class="{
                        selected: compensationByFixAmount && !compensationByRepairment,
                      }"
                      @click="
                        {
                          (compensationByFixAmount = true), (compensationByRepairment = false);
                        }
                      "
                    >
                      <div class="row align-items-center">
                        <div class="col">
                          <h6 class="text-center mb-0 mt-2">只賠錢</h6>
                        </div>
                      </div>
                    </div>
                    <div
                      id="compensation-option-2"
                      class="col-4 card"
                      :class="{
                        selected: !compensationByFixAmount && compensationByRepairment,
                      }"
                      @click="
                        {
                          (compensationByFixAmount = false), (compensationByRepairment = true);
                        }
                      "
                    >
                      <div class="row align-items-center">
                        <div class="col">
                          <h6 class="text-center mb-0 mt-2">只修車</h6>
                        </div>
                      </div>
                    </div>
                    <div
                      id="compensation-option-3"
                      class="col-4 card"
                      :class="{
                        selected: compensationByFixAmount && compensationByRepairment,
                      }"
                      @click="
                        {
                          (compensationByFixAmount = true), (compensationByRepairment = true);
                        }
                      "
                    >
                      <div class="row align-items-center">
                        <div class="col">
                          <h6 class="text-center mb-0 mt-2">賠錢 + 修車</h6>
                          <div class="text-center"><small>(營業損失)</small></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg mb-4" v-show="compensationByFixAmount">
                  <div class="p-4 card">
                    <h5 class="mb-0">選擇賠錢方式</h5>
                    <hr />
                    <div class="row mb-3 align-items-center">
                      <div class="col-auto">新台幣</div>
                      <div class="col">
                        <b-form-input
                          id="compensationAmount"
                          v-model="$v.compensationAmount.$model"
                          :state="validateState('compensationAmount')"
                          type="number"
                          placeholder="金額"
                        />
                        <b-form-invalid-feedback id="compensationAmount">
                          必填
                        </b-form-invalid-feedback>
                      </div>
                      <div class="col-auto">元整</div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12 mb-3">
                        <b-form-radio v-model="paymentType" name="pay_methods" value="CASH">
                          現金支付
                          <br />
                          <small>(賠償者當場支付現金)</small>
                        </b-form-radio>
                      </div>
                      <div class="col-12">
                        <b-form-radio v-model="paymentType" name="pay_methods" value="TRANSFER">
                          轉帳付款
                          <br />
                          <small>(賠償者取得匯款帳號之後付款)</small>
                        </b-form-radio>
                      </div>
                    </div>
                    <div
                      class="row align-items-center"
                      :class="{ disabled: paymentType !== 'TRANSFER' }"
                    >
                      <div class="col-auto">賠償者</div>
                      <div class="col">
                        <b-form-input
                          id="transferDays"
                          v-model="$v.transferDays.$model"
                          :state="validateState('transferDays')"
                          type="number"
                          placeholder="天數"
                        />
                        <b-form-invalid-feedback id="transferDays">必填</b-form-invalid-feedback>
                      </div>
                      <div class="col-auto">天內匯款</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg mb-4" v-show="compensationByRepairment">
                  <div class="p-3 card h-100">
                    <h5 class="mb-0">選擇修車方式</h5>
                    <hr />
                    <h6>修車廠選擇權</h6>
                    <div class="row mt-2 mb-5">
                      <div class="col-6">
                        <b-form-radio
                          v-model="repairShopDecisionSide"
                          name="repair_methods"
                          value="CREATOR"
                        >
                          我方決定
                        </b-form-radio>
                      </div>
                      <div class="col-6">
                        <b-form-radio
                          v-model="repairShopDecisionSide"
                          name="repair_methods"
                          value="OTHERS"
                        >
                          對方決定
                        </b-form-radio>
                      </div>
                    </div>
                    <h6>修車廠類型</h6>
                    <div class="row mt-2 mb-5">
                      <div class="col-6">
                        <b-form-radio v-model="isOemOnly" name="repair_types" value="false">
                          原廠或副廠皆可
                        </b-form-radio>
                      </div>
                      <div class="col-6">
                        <b-form-radio v-model="isOemOnly" name="repair_types" value="true">
                          只能原廠
                        </b-form-radio>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-5">
              <h4>我方資訊</h4>
              <div class="row mb-3">
                <div class="col">
                  <h6>姓名</h6>
                  <b-form-input
                    id="creatorName"
                    v-model="$v.creatorName.$model"
                    :state="validateState('creatorName')"
                    type="text"
                    disabled
                  />
                  <b-form-invalid-feedback id="creatorName">必填</b-form-invalid-feedback>
                </div>
                <div class="col">
                  <h6>手機號碼</h6>
                  <b-form-input
                    id="creatorMobile"
                    v-model="$v.creatorMobile.$model"
                    :state="validateState('creatorMobile')"
                    type="tel"
                    disabled
                  />
                  <b-form-invalid-feedback id="creatorMobile">必填</b-form-invalid-feedback>
                </div>
              </div>
            </div>
            <div class="mb-5">
              <h4>對方資訊</h4>
              <div class="row mb-3">
                <div class="col">
                  <h6>姓名</h6>
                  <b-form-input
                    id="oppositeName"
                    v-model="$v.oppositeName.$model"
                    :state="validateState('oppositeName')"
                    type="text"
                  />
                  <b-form-invalid-feedback id="oppositeName">必填</b-form-invalid-feedback>
                </div>
                <div class="col">
                  <h6>手機號碼</h6>
                  <b-form-input
                    id="oppositeMobile"
                    v-model="$v.oppositeMobile.$model"
                    :state="validateState('oppositeMobile')"
                    type="tel"
                  />
                  <b-form-invalid-feedback id="oppositeMobile">必填</b-form-invalid-feedback>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col">
                <Notes
                  text="請輸入正確的手機號碼，以確保您的法律權益並收到合約"
                  icon="exclamation-circle"
                />
              </div>
            </div>
          </div>
          <div v-show="step == 1">
            <div class="text-center mb-5">
              <div>
                <b>合約已發送至</b>
              </div>
              <h2>{{ oppositeMobile }}</h2>
              <div class="mb-1">
                <a href="#" @click="step = 0"><b-icon icon="pencil" class="mr-2" />修改號碼</a>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-7">
                <div class="p-3 mb-2 validationSteps" id="step1">
                  <div class="row">
                    <div class="col-auto pr-0"><h3>1</h3></div>
                    <div class="col-7">
                      <div><b>對方收到簡訊</b></div>
                      <div class="mb-1">
                        <small
                          >對方會收到一則簡訊，內含一組 6 位數同意代碼以及合約的 PDF 檔。</small
                        >
                      </div>
                      <button @click="previewModalShow = true">
                        <b-icon icon="eye" class="mr-2" />預覽合約
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="p-3 mb-2 validationSteps" id="step2">
                  <div class="row">
                    <div class="col-auto pr-0"><h3>2</h3></div>
                    <div class="col-7">
                      <div><b>跟對方索取同意碼</b></div>
                      <div class="mb-1">
                        <small>對方不在現場時，可撥打電話詢問對方</small>
                      </div>
                      <a :href="'tel:' + oppositeMobile">
                        <button><b-icon icon="phone" class="mr-2" />{{ oppositeMobile }}</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="p-3 mb-2 validationSteps" id="step3">
                  <div class="row">
                    <div class="col-auto pr-0"><h3>3</h3></div>
                    <div class="col">
                      <div><b>輸入對方同意代碼</b></div>
                      <div class="mb-1">
                        <small>如對方確認合約無誤，則向對方索取６位數和解同意代碼。</small>
                      </div>
                      <div class="row align-items-top">
                        <div class="col pr-0">
                          <b-form-input
                            id="validationCode"
                            v-model="$v.validationCode.$model"
                            :state="validateState('validationCode') && validationCodeStatus"
                            type="number"
                            placeholder="6 位數同意代碼"
                          />
                          <b-form-invalid-feedback id="cCode">
                            請輸入正確的同意代碼
                          </b-form-invalid-feedback>
                        </div>
                        <div class="col-auto mt-2" @click="sendContract(currentContract.id, false)">
                          <a href="#"
                            ><b-icon class="mr-1" icon="bootstrap-reboot"></b-icon>重新發送</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="step == 2" class="h-100">
            <div class="row align-items-center justify-content-center text-center h-100">
              <div class="col align-self-center">
                <h1 class="display-1">
                  <b-icon icon="check-circle"></b-icon>
                </h1>
                <h4>和解完成</h4>
                <h6>若「有人受傷」，恐涉及刑責，請列印「撤回告訴狀」請對方簽名</h6>
                <a href="./withdraw-doc.pdf" target="_blank">
                  <b-icon icon="cloud-download"></b-icon>下載撤回告訴狀
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading
      :active.sync="requestingCreateContract"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.8"
    >
      <div class="loading-text" slot="after">正在產生預覽合約</div>
    </loading>
    <loading
      :active.sync="requestingSendContractToOpposite"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.8"
    >
      <div class="loading-text" slot="after">和解書及同意代碼重新發送中</div>
    </loading>
    <loading
      :active.sync="requestingVerifyContract"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.8"
    >
      <div class="loading-text" slot="after">正在產生正式和解文件</div>
    </loading>
    <PreviewContract
      v-if="previewModalShow"
      :onClose="closePreviewModal"
      :url="`${s3_host}/${currentContract ? currentContract.previewedPdf : ''}`"
    />
    <NavBar
      :step="step"
      :states="states"
      :end="end"
      v-on:onSubmit="onSubmit"
      v-on:nextStep="onNextStep(step)"
      v-on:prevStep="step -= 1"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Loading from 'vue-loading-overlay';
import ProgressBar from '@/components/ProgressBar.vue';
import NavBar from '@/components/NavBar.vue';
import Notes from '@/elements/Notes.vue';
import PreviewContract from '@/components/PreviewContract.vue';
import { auth, contract } from '@/mixins';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  mixins: [auth, contract, validationMixin],
  name: 'CreateContract',
  props: {},
  components: {
    Notes,
    NavBar,
    ProgressBar,
    Loading,
    PreviewContract,
  },
  computed: {
    ...mapState({
      currentRecord: (state) => state.records.currentRecord,
      currentContract: (state) => state.contracts.currentContract,
      requestingCreateContract: (state) => state.contracts.requestingCreateContract,
      requestingSendContractToOpposite: (state) => state.contracts.requestingSendContractToOpposite,
      requestingVerifyContract: (state) => state.contracts.requestingVerifyContract,
      profile: (state) => state.auth.profile,
    }),
  },
  validations() {
    return {
      creatorName: { required },
      creatorMobile: { required },
      oppositeName: { required },
      oppositeMobile: { required },
      compensationAmount: this.compensationByFixAmount ? { required } : {},
      transferDays:
        this.compensationByFixAmount && this.paymentType === 'TRANSFER' ? { required } : {},
      validationCode: this.step === 1 ? { required } : {},
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    closePreviewModal() {
      this.previewModalShow = false;
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.validationCode.$error) {
        return;
      }

      this.verifyContract({
        id: this.currentContract.id,
        code: this.validationCode,
        onSuccess: () => {
          this.step += 1;
        },
        onFailed: () => {
          this.validationCodeStatus = false;
          alert('驗證碼錯誤，請再試一次');
        },
      });
    },
    onNextStep() {
      if (this.step === 0) {
        this.$v.$touch();
        if (
          this.$v.$anyError ||
          (this.compensator !== null &&
            !this.compensationByFixAmount &&
            !this.compensationByRepairment)
        ) {
          return;
        }

        const {
          currentRecord,
          compensator,
          compensationByFixAmount,
          compensationAmount,
          paymentType,
          transferDays,
          compensationByRepairment,
          repairShopDecisionSide,
          isOemOnly,
          creatorName,
          creatorMobile,
          oppositeName,
          oppositeMobile,
        } = this;
        const getCompensationType = () => {
          if (compensationByFixAmount && compensationByRepairment) {
            return 'REPAIRMENT_FIXED_AMOUNT';
          }
          if (compensationByFixAmount) return 'FIXED_AMOUNT';
          return 'REPAIRMENT';
        };
        this.createContract({
          accidentRecordId: currentRecord.id,
          ...(compensator !== null && { compensationType: getCompensationType() }),
          ...(compensator !== null && compensationByFixAmount && { compensationAmount }),
          ...(compensator !== null &&
            compensationByRepairment && { isOemOnly: isOemOnly === 'true' }),
          partyList: [
            {
              creator: true,
              mobile: creatorMobile,
              name: creatorName,
            },
            {
              creator: false,
              mobile: oppositeMobile,
              name: oppositeName,
            },
          ],
          payer: compensator,
          ...(compensator !== null && compensationByFixAmount && { paymentType }),
          ...(compensator !== null && compensationByRepairment && { repairShopDecisionSide }),
          ...(compensator !== null &&
            compensationByFixAmount &&
            paymentType === 'TRANSFER' && { transferDays }),
          onSuccess: () => {
            this.$v.$reset();
            this.step += 1;
          },
          onFailed: () => {
            alert('建立合約失敗，請再試一次');
          },
        });
      }
    },
    sendContract(id, isNextPage) {
      this.sendContractToOpposite({
        id,
        mobile: this.oppositeMobile,
        mobileCountryCode: '886',
        onSuccess: () => {
          if (isNextPage) {
            this.step += 1;
          } else {
            alert('簡訊已送出');
          }
        },
        onFailed: () => {
          alert('發生錯誤，請再試一次');
        },
      });
    },
  },
  data() {
    return {
      s3_host: process.env.VUE_APP_S3_HOST,
      step: 0,
      states: ['撰寫合約', '雙方確認'],
      end: {
        link: '/contract',
        action: '查看合約',
      },
      previewModalShow: false,
      compensator: 'CREATOR',
      compensationByFixAmount: true,
      compensationAmount: 0,
      paymentType: 'CASH',
      transferDays: 0,
      compensationByRepairment: false,
      repairShopDecisionSide: 'CREATOR',
      isOemOnly: false,
      creatorName: '',
      creatorMobile: '',
      oppositeName: '',
      oppositeMobile: '',
      validationCode: '',
      validationCodeStatus: null,
    };
  },
  watch: {
    profile: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.creatorName = newVal.lastName + newVal.firstName;
          this.creatorMobile = `0${newVal.mobile}`;
        }
      },
    },
    previewModalShow: {
      handler(newVal) {
        if (newVal) {
          document.body.style.position = 'fixed';
          document.body.style.top = `-${window.scrollY}px`;
        } else {
          const scrollY = document.body.style.top;
          document.body.style.position = '';
          document.body.style.top = '';
          window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        }
      },
    },
  },
};
</script>
<style lang="less">
@import '@/assets/lessjs/variables.less';

.required {
  color: #ff0000;
  font-size: 24px;
}

.vld-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading-text {
  margin-top: 16px;
}

.card {
  background-color: @bg-grey;
  cursor: pointer;
  padding-bottom: 35%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-blend-mode: luminosity;
  background-size: 100%;
  border-radius: 0;
  &.selected {
    background-color: @highlight;
  }
  &#compensator-option-0 {
    background-image: url('../assets/bg_compensator_0@2x.png');
  }
  &#compensator-option-1 {
    background-image: url('../assets/bg_compensator_1@2x.png');
  }
  &#compensator-option-2 {
    background-image: url('../assets/bg_compensator_2@2x.png');
  }
  &#compensation-option-1 {
    background-image: url('../assets/bg_compensation_1@2x.png');
  }
  &#compensation-option-2 {
    background-image: url('../assets/bg_compensation_2@2x.png');
  }
  &#compensation-option-3 {
    background-image: url('../assets/bg_compensation_3@2x.png');
  }
}

.verify-note-bubble {
  color: white;
  background-color: @main;
  padding: 10px;
  z-index: 2;
  font-size: 0.8rem;
  display: inline-block;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}

button {
  background-color: @highlight;
  color: white;
  border: none;
  padding: 5px 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.336);
}

.validationSteps {
  border: 1px solid @light-grey;
  background-color: @bg-grey;
  border-radius: 10px;
  overflow: hidden;
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-blend-mode: luminosity;
  position: relative;
  .row {
    position: relative;
    z-index: 1;
  }
  &#step1 {
    background-image: url('../assets/bg_validation_step_1@2x.png');
  }
  &#step2 {
    background-image: url('../assets/bg_validation_step_2@2x.png');
  }
  &::after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: rgb(180, 157, 95);
    background: linear-gradient(-90deg, #f7f7f700 0%, #f7f7f7 45%, #f7f7f7 100%);
  }
}
</style>
